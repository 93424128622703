<template>
  <!-- profile css 사용 -->
  <div class="container profile">
    <page-header :title="'결제를 취소합니다'" />

    <div class="ui-mt-3 ui-mb-3">
      <h4>환불 및 취소 사유</h4>
      <template v-for="(reason, key) in cancelReason">
        <left-check-box
          :key="key"
          class="ui-mt-0 ui-mb-0"
          :text="reason"
          v-model="input.reason[key]"
          @click="result => {
            if (result.checked) {
              for (let i in input.reason) {
                input.reason[i] = i === key
              }
              form.reason = key
              result.update()
            }
          }"
          />
      </template>
    </div>

    <div class="ui-border-line ui-mb-4" style="height: 10px"></div>

    <div class="profile-info">
      <h4>환불 및 취소 금액</h4>
      <div class="ui-border-line ui-mt-3 ui-mb-1"></div>
      <!--  -->
      <div class="info-block">
        <ul>
          <li>결제 금액</li>
          <li class="float-right">{{isPartCancel ? cancellableAmt : $lib.addComma(payAmt)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block" v-if="pricePenaltyPer > 0">
        <ul>
          <li>위약금 ({{pricePenaltyPer}}%)</li>
          <li class="float-right">{{$lib.addComma(pricePenalty)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block">
        <ul>
          <li class="color-lavender ui-weight-6">최종 취소 금액</li>
          <li class="color-lavender ui-weight-6 float-right">{{$lib.addComma(canCancel ? totalPrice : 0)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1"></div>
      </div>
      <!--  -->

    </div>

    <div class="ui-border-line" style="height: 10px"></div>

    <div class="profile-teamDesc">
      <h4>환불 규정 및 입금 안내</h4>
      <p class="ui-p-indent-1">결제/섭외를 취소하는 경우 아래의 규정을 따릅니다.<br>
      <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 위약금 50%</span><br>
      <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 및 환불 불가</span></p>
      <br>
      <p class="ui-p-indent-1">엔터테이너가 취소 시 아래의 규정을 따릅니다.<br>
      <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 결제금액의 50%를 배상</span><br>
      <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 불가, 300%를 배상</span><br>
      <span class="ui-p-indent color-lavender text-underline" @click="$router.push('/etc/refund-policy')">자세히 알아보기</span></p>
    </div>

    <template v-if="isClient && canCancel">
      <footer-box
        :submit-text="'결제 취소'"
        :submit-cb="() => paymentAction('artistCancel')"
        :submit-disabled="submitDisabled"
      >
      </footer-box>
    </template>

  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import LeftCheckBox from '@/components/common/LeftCheckBox'

export default {
  name: 'PaymentCancel',
  components: {
    PageHeader,
    Icon,
    FooterBox,
    LeftCheckBox,
  },
  data() {
    return {
      offerData: {},
      artistData: {},
      cancelReason: {
        0: '행사가 취소되거나 일정이 변경되어서',
        1: '전문가의 요청으로 취소',
        2: '다른 엔터테이너로 교체하기 위해서',
        3: '금액이나 조건이 변경되어서',
        4: '그 외 기타 사유로',
      },
      form: {
        reason: null,
      },
      input: {
        reason: {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
        },
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    payAmt() {
      return Math.floor(Number(this.offerData.payAmt || 0))
    },
    cancellableAmt() {
      return Math.floor(Number(this.offerData.cancellableAmt || 0))
    },
    proPrice() {
      return Math.floor(Number(this.artistData.proPrice || 0))
    },
    pricePenaltyPer() {
      return this.$config.constant.paymentRelatedDates.penaltyPercent(this.remainDays)
    },
    pricePenalty() {
      let pricePenalty;
      if(this.isPartCancel){
        pricePenalty = Math.floor((Number(this.cancellableAmt) * this.pricePenaltyPer) / 100)
      }else{
        pricePenalty = Math.floor((Number(this.payAmt) * this.pricePenaltyPer) / 100)
      }
      return pricePenalty
    },
    totalPrice() {
      let totalPrice;
      if(this.isPartCancel){
        totalPrice = Number(this.cancellableAmt) - this.pricePenalty
      }else{
        totalPrice = Number(this.payAmt) - this.pricePenalty
      }
      return totalPrice
    },
    canCancel() {
      return this.$config.constant.paymentRelatedDates.canCancel(this.remainDays)
    },
    isPartCancel() {
      if (['partCancel'].indexOf(this.offerData?.nowState) !== -1) return true
      return false
    },
    submitDisabled() {
      // ing:모집중
      // wait:입금대기
      // end:섭외완료
      // cancelRequest:결제취소요청
      // cancel:결제취소
      // partCancel:부분취소
      // payRequest:지급요청
      // pay:지급완료
      // selfEnd:본인직접마감

      return !this.canCancel || !( ['end'].indexOf(this.offerData.nowState) !== -1 || ['partCancel'].indexOf(this.offerData.nowState) !== -1)|| this.form.reason === null
    },
    remainDays() {
      const now = moment(moment(new Date()).format('YYYY-MM-DD'))
      const end = moment(moment(Number(this.offerData.playDate) * 1000).format('YYYY-MM-DD'))
      return end.diff(now, 'days')
    },
  },
  created() {
    this.getOffer()

    if (this.isArtist) {
      this.$router.replace(`/payment/${this.$route.params.offerUid}/${this.userData.useridx}/result`)
      return
    }

    if (this.$route.params.artistidx == 0) {
      this.$toast('전문가 정보를 불러오는데 실패하였습니다.')
    } else {
      let artistData = sessionStorage.getItem(`artistData:${this.$route.params.artistidx}:${this.$route.params.offerUid}`)
      if (artistData) {
        this.artistData = JSON.parse(artistData)
      } else {
        this.getProfile()
      }
    }
  },
  methods: {
    getOffer() {
      const req = {
        method: 'get',
        url: `/client/offer/${this.$route.params.offerUid}`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.offerData = data.response.offerData || {}

            if (this.isClient && this.offerData.useridx != this.userData.useridx) {
              this.$toast('잘못된 접근입니다').back()
            }
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    getProfile() {
      const { offerUid, artistidx } = this.$route.params
      const req = {
        method: 'get',
        url: `/artist/profile/${artistidx}`,
      }

      if (offerUid) {
        req.method = 'post'
        req.data = {
          offerUid,
        }
        if (this.isClient) req.data.offerRead = 'y'
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.artistData = data.response.artistData || {}
            // sessionStorage.setItem(`artistData:${this.artistData.artistidx}:${this.$route.params.offerUid}`, JSON.stringify(this.artistData))
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    /**
     * 대금지급하기 버튼 클릭
     * @param {'payRequest' | 'artistCancel'} action
     */
    paymentAction(action) {
      if (action !== 'artistCancel' || this.form.reason === null) {
        return
      }

      const req = {
        method: 'put',
        url: `/client/offer/${this.$route.params.offerUid}/state/${action}`,
        data: {
          msg: this.cancelReason[this.form.reason]
        }
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$historyStack.mulitPush('/home', [
              '/client/my-offer',
              `/client/my-offer/${this.$route.params.offerUid}/${this.$route.params.artistidx}`,
            ])
            this.$nextTick().then(() => {
              this.$toast('취소가 완료되었습니다.')
              sessionStorage.removeItem(`couponDiscountPrice:${this.$route.params.offerUid}`)
            })
          } else {
            let errMsg = {
              '-2': '의뢰 정보가 없습니다.',
              '-3': '취소할 수 없는 상태입니다.',
              '-4': '취소 중 오류가 발생하였습니다.',
            }
            this.$alert(errMsg[resultData.code] || resultData)
          }
        })
        .catch(log.error)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.payment-header {
  background-color: $color-deepLavender;
  color: #fff;
  text-align: center;
  margin: 0 -3rem;
  overflow: hidden;
  .title {
    margin: 3rem 0;
    font-size: 3.2rem;
    font-weight: 600;
  }
  .text {
    margin: 3rem 0;
    font-size: 2rem;
    line-height: 1.5;
  }
  .image {
    div {
      margin: 0 auto;
    }
  }
}

.artist-image {
  display: block;
  float: left;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-top: -1rem;
  margin-bottom: -0.6rem;
  margin-right: 1rem;
}
#page {
  > .container.profile {
    .profile-info .info-block ul li {
      &:first-child {
        width: auto;
        color: #464646;
      }
      &:nth-child(2) {
        font-weight: 500;
      }
    }
  }

  h4 {
    font-weight: 500;
    font-size: 2.4rem;
    margin: 2rem 0
  }
}
</style>
